* {
  -webkit-appearance: none;
  box-sizing: border-box;
  border-radius: 0;
  outline: none;
}
body {
  margin: 0;
  background-color: #202e4711;
  font-family: sans-serif;
  font-weight: 300;
}
a {
  text-decoration: none;
  color: currentColor;
  cursor: pointer;
}
h1 {
  font-weight: 500;
  font-size: 18pt;
  color: #202e47;
}
table {
  width: 100%;
  text-align: left;
  border-spacing: 0 2px;
}
table.condensed {
  table-layout: fixed;
}
table.condensed > thead > tr > th {
  height: 320px;
  vertical-align: bottom;
}
table.condensed > thead > tr > th > span {
  display: inline-block;
  white-space: nowrap;
  transform: rotate(-90deg) translateX(-12pt);
  transform-origin: 0 0;
}
thead {
  background-color: #202e4709;
}
tbody {
  background-color: #202e4706;
}
th,
td {
  padding: 8px;
}
.card {
  position: relative;
  float: left;
  margin: 0 0 12px 0;
  width: 100%;
  background-color: #fff;
  color: #202e47;
  border-radius: 4px;
  overflow: hidden;
}
.card:last-of-type {
  margin: 0;
}
.card--padded {
  padding: 12px 18px;
}
.card--center {
  text-align: center;
}
.card--ghosted {
  background-color: transparent;
}
section {
  border-bottom: 1px solid #202e4711;
}
section:last-of-type {
  border-bottom: none;
}
.fields {
  width: 100%;
  padding: 18px 18px 6px 18px;
  overflow: auto;
}
.fields h1,
.fields h2,
.fields p,
.fields li {
  margin: 8px 0;
}
.field {
  float: left;
  margin: 0 0 12px 0;
  width: 100%;
}
.field--with--dropdown {
  position: relative;
}
label > span {
  color: #5b7082;
}
input,
textarea,
select {
  border: 1px solid #d2d5da;
  width: 100%;
  padding: 8px;
  background-color: #fff;
  font-family: sans-serif;
  font-weight: 300;
  font-size: 12pt;
  color: #202e47;
  border-radius: 2px;
}
input:focus,
textarea:focus,
select:focus {
  border: 1px solid #0099ff;
}
input:disabled,
textarea:disabled,
select:disabled {
  border: 1px solid #00000009;
  background-color: transparent;
}
label > input,
label > textarea,
label > select {
  margin: 6px 0 0 0;
}
option {
  font-weight: 300;
}
.dropdown {
  display: none;
  position: absolute;
  top: 56px;
  border: solid #0099ff;
  border-width: 0 1px 1px 1px;
  width: 100%;
  padding: 4px 8px;
  background-color: #fff;
  border-radius: 0 0 2px 2px;
}
input:focus + .dropdown {
  display: block;
}
.dropdown > span {
  font-size: 9pt;
  color: #0099ff;
}
button {
  border: unset;
  margin: unset;
  padding: unset;
  background-color: unset;
  font-family: sans-serif;
  font-weight: 300;
  font-size: 12pt;
  cursor: pointer;
}
.field > button,
.field > a > button,
td > button {
  border: 1px solid #d2d5da;
  padding: 8px;
  background-color: #fff;
  color: #202e47;
  border-radius: 2px;
}
.field > button:focus,
.field > button:hover,
.field > a > button:focus,
.field > a > button:hover,
td > button:focus,
td > button:hover {
  background-color: #202e4711;
}
.field > button.primary,
.field > a > button.primary,
td > button.primary {
  border: 1px solid #0099ff;
  background-color: #0099ff;
  font-weight: 400;
  color: #fff;
}
.field > button.primary:focus,
.field > button.primary:hover,
.field > a > button.primary:focus,
.field > a > button.primary:hover,
td > button.primary:focus,
td > button.primary:hover {
  border: 1px solid #7fc9ff;
  background-color: #7fc9ff;
}
.columns {
  width: 100%;
  overflow: auto;
}
.column {
  float: right;
  margin: 24px auto 0 auto;
  width: 100%;
  overflow: auto;
}
.top-nav {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: 48px;
  background-color: #202e47;
  z-index: 2;
}
#top-nav__left,
#top-nav__right {
  margin: 0;
  padding: 0;
  list-style: none;
}
#top-nav__left {
  float: left;
  margin: 0 0 0 8px;
}
#top-nav__right {
  float: right;
  margin: 0 8px 0 0;
}
#top-nav__left > li,
#top-nav__right > li {
  display: inline-block;
  position: relative;
}
#top-nav__left > li {
  margin: 8px 8px 8px 0;
}
#top-nav__right > li {
  margin: 8px 0 8px 8px;
}
#top-nav__left > li > a > span,
#top-nav__left > li > button > span,
#top-nav__right > li > a > span,
#top-nav__right > li > button > span {
  display: inline-block;
  padding: 8px;
  line-height: 16px;
  color: #fff;
  border-radius: 2px;
}
#top-nav__left > li > a:focus > span,
#top-nav__left > li > a:hover > span,
#top-nav__left > li > button:focus > span,
#top-nav__left > li > button:hover > span,
#top-nav__right > li > a:focus > span,
#top-nav__right > li > a:hover > span,
#top-nav__right > li > button:focus > span,
#top-nav__right > li > button:hover > span {
  background-color: #ffffff11;
}
.top-nav__extra {
  display: none;
}
.side-nav {
  position: fixed;
  top: 48px;
  bottom: 0;
  left: -100%;
  width: 100%;
  padding: 0 0 88px 0;
  background-color: #d2d5da;
  overflow-y: scroll;
  z-index: 1;
  transition: left 0.2s ease;
}
.side-nav:focus-within {
  left: 0;
}
.side-nav.on {
  left: 0;
}
#side-nav__logo {
  width: 100%;
  height: 108px;
  padding: 32px;
}
#side-nav__logo > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center 0;
}
#side-nav__pages {
  margin: 0;
  padding: 0;
  list-style: none;
  border-radius: 2px;
  overflow: hidden;
}
#side-nav__pages > li {
  margin: 0 8px 8px 8px;
}
#side-nav__pages > li > a > span {
  display: inline-block;
  width: 100%;
  padding: 8px;
  color: #202e47;
}
#side-nav__pages > li > a:focus > span,
#side-nav__pages > li > a:hover > span {
  background-color: #202e4711;
}
#side-nav__pages > li > a.active > span {
  background-color: #202e4711;
  font-weight: 400;
}
.auth-wrapper {
  margin: 0;
}
.basic-wrapper {
  margin: 48px 0 0 0;
}
#page {
  margin: 0 auto;
  padding: 0 24px 24px 24px;
}
#auth__logo {
  width: 100%;
}
@media (min-width: 544px) {
  .card {
    margin: 0 0 16px 0;
  }
  .card--padded {
    padding: 16px 24px;
  }
  .fields {
    padding: 24px 24px 8px 24px;
  }
  .field {
    margin: 0 0 16px 0;
  }
  .column--thin {
    float: none;
    max-width: 480px;
  }
  label > input,
  label > textarea,
  label > select {
    margin: 8px 0 0 0;
  }
  .column {
    margin: 64px auto 0 auto;
  }
  .top-nav__extra {
    display: inline;
  }
  .side-nav {
    padding: 0;
  }
  #page {
    padding: 0 32px 64px 32px;
  }
}
@media (min-width: 800px) {
  #top-nav__left > #top-nav__menu {
    display: none;
  }
  .side-nav {
    left: 0;
    width: 256px;
  }
  .basic-wrapper {
    margin: 48px 0 0 256px;
  }
}
@media (min-width: 1296px) {
  .field--half {
    width: calc((100% - 16px) / 2);
  }
  .field--half:nth-of-type(2n-1) {
    margin: 0 16px 16px 0;
  }
  .field--half:nth-of-type(2n) {
    margin: 0 0 16px 0;
  }
}
@media (min-width: 1792px) {
  #page {
    width: 1536px;
  }
}
